import { Injectable } from '@angular/core';
import { catchError, tap, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';

//RUTA DEL API
let apiUrl = 'http://ipsanet.com/bknd';
const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

constructor(public http: HttpClient) { }
	
	
	
 listado(lista): Observable<any> {
  const url = apiUrl+'/getprodlist.php?lista='+lista;
  return this.http.get<any>(url).pipe(
    tap(() => console.log('la consulta paso')),
    catchError(this.handleError<any>('se presento un error'))
  );
}
	
	
	
	pedidos(id): Observable<any> {
  const url = apiUrl+'/getpeds.php?iduser='+id;
  return this.http.get<any>(url).pipe(
    tap(() => console.log('la consulta paso')),
    catchError(this.handleError<any>('se presento un error'))
  );
}
	
	
	
	
	guardarPedido(data): Observable<any> {
  const url = apiUrl+'/saveped.php';
  return this.http.post<any>(url,data).pipe(
    tap(() => console.log('la consulta paso')),
    catchError(this.handleError<any>('se presento un error'))
  );
}
	
  private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}
	
	
	
	
}

