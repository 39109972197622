import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtro'
})
export class FiltroPipe implements PipeTransform {

  transform(value: any[], uno?: any,dos?: any): any {
	 
	  if (typeof(value) === 'undefined' || typeof(uno) === 'undefined') {
      return 0;
    }

    var sum = 0;
    for (var i = value.length - 1; i >= 0; i--) {
		var val1=Number(value[i][dos]);
		var val2=Number(value[i][uno]);
		var res=Number(val1*val2);
      sum += res;
    }
    return sum.toFixed(2);
	  
	  
	  
  }
	
	
	
	
    
 
	
	
	
	

}
